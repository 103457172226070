/*
1. Variables
2. Reset css
3. Fonts
4. Overrides
*/

/* 1. Variables Start */
:root {
  --clr-primary: #f72e36;
  --clr-secondary: #000;
  --clr-disabled: #e6e8eb;

  --border: #e8e7e6;

  --clr-danger: #da1919;

  --bg-primary: rgba(0, 0, 0, 0.04);
  --bg-secondary: rgba(0, 0, 0, 0.08);

  --text-white: #fff;
  --text-disabled: #9c9494;
  --text-faded: #666;
}

/* 1. Variables End */

/* 2. Reset css Start */
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  transition-timing-function: ease-in;
  font-family: "Kanit-Light", sans-serif;
  font-weight: 300;
}

html {
  font-size: 16px;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

button {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 2. Reset css End */

// Fonts
@font-face {
  font-family: "Kanit-Light";
  src: url("/fonts/Kanit-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("/fonts/Kanit.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Kanit-SemiBold";
  src: url("/fonts/Kanit-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Kanit-Bold";
  src: url("/fonts/Kanit-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Kanit-ExtraBold";
  src: url("/fonts/Kanit-ExtraBold.ttf");
  font-weight: 800;
  font-display: swap;
}

.container {
  max-width: 1120px;
  padding: 0px 0px;
  margin: 0px auto;
}

#nprogress .bar {
  height: 6px !important;
  background: #f72e36 !important;
}

.nav-wrap {
  border-bottom: 1px solid var(--border);
  box-shadow: 0 0 8px 2px rgb(0 0 0 / 20%);
}

.nav {
  height: 60px;
  width: 100%;
  position: relative;
  padding: 0px 15px;
}

.nav > .nav-header {
  display: inline-block;
}

.nav-logo-img {
  display: inline-block;
  position: relative;
  width: 148px;
  height: 48px;
  margin: 5px 0px;
}

.nav > .nav-btn {
  display: none;
}

.nav > .nav-links {
  display: inline;
  float: right;
  font-size: 18px;
}

.add-deliveries {
  background-color: #ffb8ba
}

.add-support {
  background-color: #ffb8ba
}
.nav > .nav-links > a {
  display: inline-block;
  padding: 16px;
  text-decoration: none;
  color: #000;
}

.nav > .nav-links > a:hover {
  background-color: #ffb8ba;
}

.nav > #nav-check {
  display: none;
}

@media (max-width: 767px) {
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .nav > .nav-btn > label {
    width: 60px;
    height: 60px;
    padding: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nav > .nav-btn > label:hover,
  .nav #nav-check:checked ~ .nav-btn > label {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 8px;
    border-top: 2px solid var(--clr-secondary);
  }

  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color: #fff;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 60px;
    left: 0px;
    z-index: 2;
  }

  .nav > .nav-links > a {
    display: block;
    width: 100%;
  }

  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }

  .nav > #nav-check:checked ~ .nav-links {
    height: calc(100vh - 60px);
    overflow-y: auto;
  }
}

input[type="text"] {
  width: 100%;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  border: none;
  background: var(--bg-secondary);
  position: relative;
}

input[type="email"] {
  width: 100%;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  border: none;
  background: var(--bg-secondary);
  position: relative;
}

.input:not(:last-child) {
  margin-bottom: 12px;
}

input[type="text"]:focus {
  border: none;
  outline: none;
}

input[type="email"]:focus {
  border: none;
  outline: none;
}

.input {
  font-family: "Kanit";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--text-faded);
}

// input[type="file"] {
//   display: none;
// }

.text-area {
  font-family: "Kanit";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  width: 100%;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  border: none;
  background: var(--bg-secondary);
  position: relative;
  max-width: 100%;
}
